import request from '../request.js'
export function getProject(pageIndex,pageSize){
    return request({  //利用我们封装好的request发送请求
        url: '/project/admin/get/list',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'get',//请求方法
        params:  {"pageIndex":pageIndex,"pageSize":pageSize}//向后端传递数据
    })
}
export function updateProject(data){
    return request({  //利用我们封装好的request发送请求
        url: '/project/update',//请求地址 已经去除前面request中baseUrl相同的内容
        method: 'post',//请求方法
        data:data//向后端传递数据
    })
}
