<template>
  <div>
    <el-table
        :data="tableData"
        border
        style="width: 100%">
      <el-table-column
          type="selection"
          width="55">
      </el-table-column>

      <el-table-column
          label="项目id"
          prop="id"
          width="80"
      >
      </el-table-column>
      <el-table-column
          label="项目名称"
          prop="projectName"
          width="150"
      >
      </el-table-column>
      <el-table-column
          label="项目价格"
          prop="money"
          width="80"
      >
      </el-table-column>
      <el-table-column
          label="创建时间"
          prop="createDate"
          width="200"
      >
      </el-table-column>

      <el-table-column
          label="修改时间"
          prop="updateDate"
          width="200"
      >
      </el-table-column>
      <el-table-column
          label="上次修改人ID"
          prop="updateId"
          width="120"
      >
      </el-table-column>
      <el-table-column
          label="客户端展示语"
          prop="notes" width="150"
      >
      </el-table-column>
      <el-table-column
          label="修改人名称"
          prop="updateName" min-width="90"
      >
      </el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="primary"
              @click="handleEdit( scope.row)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-bottom: 20px">
      <el-col :span="24">
        <div style="padding-top: 20px;">
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[5, 10, 50, 100]"
              :page-size="pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
          </el-pagination>
        </div>
      </el-col>
    </el-row>
    <el-dialog title="修改项目信息"
               :visible.sync="dialogFormVisible"
               width="600px"
               top="200px">
      <el-form :model="form">
        <el-form-item label="项目名称"
                      :label-width="formLabelWidth">
          <el-input v-model="form.projectName"
                    auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="项目价格"
                      :label-width="formLabelWidth">
          <el-input v-model="form.money"
                    auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="客户端展示语"
                      :label-width="formLabelWidth">
          <el-input v-model="form.notes"
                    auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="handleSet()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {getProject,updateProject} from '../../api/projectList/index';
export default {
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pagesize: 10,
      total: 0,
      searchInfo:{
        orderState:null,
        userName:null,
        orderNumber:null,
        projectId:null
      },
      form: {
        projectName: "",
        money: "",
        notes: "",
        id: null,
      },
      formLabelWidth: '120px',
      dialogFormVisible:false
    }
  },
  methods: {
    getList(){
      let that = this;
      // let param = new URLSearchParams();
      // param.append('pageSize', this.pagesize);
      // param.append('pageNumber', this.currentPage);

      getProject(this.currentPage,this.pagesize).then(res =>{
        console.log(res);
        let len = res.data.body.list.length;
        that.total = res.data.body.count;
        that.tableData = [];
        for(let i = 0; i<len; i++){
          that.tableData.push(res.data.body.list[i]);
        }
      })

    },
    handleSizeChange: function (size) {
      this.pagesize = size;
      this.getList();
      console.log(this.pagesize)  //每页下拉显示数据
    },
    handleCurrentChange: function(currentPage){
      this.currentPage = currentPage;
      console.log(this.currentPage)  //点击第几页
      this.getList();
    },
    handleEdit ( row) {
      console.log(row)
      this.dialogFormVisible = true //开启弹出层
      //对弹出层中的表单赋值
      this.form.projectName = row.projectName
      this.form.money = row.money
      this.form.notes = row.notes
      this.form.id = row.id
    },
    handleSet ( ) {
      var data = {
        projectName: this.form.projectName,
        money: this.form.money,
        notes: this.form.notes,
        id :this.form.id
      }
      console.log(data);
      updateProject(data).then(res =>{
        if (res.data.code===1001){
          this.msgsuccess('修改成功');
          this.dialogFormVisible = false
          this.getList()
          return
        }
        this.msgerror(res.data.msg);
      })
    }
  },
  mounted(){
    this.getList();
  }
}

</script>